import React, { FC } from 'react';

type HighlightsListProps = {
	items: string[];
};

const HighlightsList: FC<HighlightsListProps> = ({ items }) => {
	return (
		<>
			<div className="divide-y divide-gray-medium">
				{items.map((item) => (
					<div className="relative py-5" key={item}>
						<div className="absolute top-6 w-3 h-3 rounded-full border-2 border-blue" />
						<h3 className="px-8 text-sm">{item}</h3>
					</div>
				))}
			</div>
		</>
	);
};

export default HighlightsList;
